import React from "react"
import { SwipeableDrawer } from "@material-ui/core"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

import "./style.css"

const Menu = ({ open, onClose, header, children, anchor = "right" }) => (
  <SwipeableDrawer
    anchor={anchor}
    open={open}
    onOpen={() => {}}
    onClose={() => onClose()}
  >
    <FontAwesomeIcon
      onClick={() => onClose()}
      style={{
        position: "absolute",
        zIndex: 100,
        top: 10,
        right: 10,
        color: "#4bbfae"
      }}
      size="2x"
      icon={faChevronRight}
    />
    <div className="menu-drawer-content">
      <span className="menu-drawer-header">
        <h1>{header}</h1>
        {children}
      </span>
    </div>
  </SwipeableDrawer>
)

export default Menu
