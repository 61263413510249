import React from "react"

import PersonCard from "../../common/PersonCard"
import * as Functions from "helpers/Functions"

import "./style.css"

const Intro = () => {
  const { width } = Functions.useWindowDimensions()
  const mobile = width < 640 ? "mobile-" : ""

  return (
    <div className={`${mobile}welcome`}>
      <div className={`${mobile}welcome-col`}>
        Technology, such wow, such cool. Tailor-made, much wow.
      </div>
      <div className={`${mobile}welcome-col`}>
        <PersonCard
          name="Jacob Harsten"
          title="VD • Founder • Engineer"
          skills="NodeJS • React • Azure • C# • .NET • ..."
        />
        <PersonCard
          name="Jonatan Jönsson"
          title="CTO • Founder • Engineer"
          skills="NodeJS • React • AWS • Spark • ..."
        />
        <PersonCard
          name="Dawid Ejdeholm Wirsén"
          title="Engineer"
          skills="NodeJS • React • C • ?..."
        />
        <PersonCard name="Joel Åsenlöv" title="CFO" skills="Moneys" />
      </div>
    </div>
  )
}

export default Intro
