import React, { useState, useRef, useEffect } from "react"

import { Link } from "react-scroll"
import { motion } from "framer-motion"

import ImageLogo from "assets/images/bhc_1x.png"
import MobileMenu from "components/common/MobileMenu"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"
import "./style.css"

import * as Functions from "helpers/Functions"

const Header = () => {
  const [sticky, setSticky] = useState(false)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const { width } = Functions.useWindowDimensions()
  const headerRef = useRef(null)

  // handle scroll event
  const handleScroll = (offset, height) =>
    setSticky(window.pageYOffset > offset + height)

  // add/remove scroll event listener
  useEffect(() => {
    var header = headerRef.current.getBoundingClientRect()

    const handleScrollEvent = () => {
      handleScroll(header.top, header.height)
    }

    window.addEventListener("scroll", handleScrollEvent)

    return () => {
      window.removeEventListener("scroll", handleScrollEvent)
    }
  }, [])

  if (width < 640) {
    return (
      <div className={`mobile-header-container`} ref={headerRef}>
        <h3 style={{ margin: "0 0 0 5vw" }}>BACKHAUS CONSULTING</h3>
        <FontAwesomeIcon
          onClick={() => setMobileMenuOpen(true)}
          style={{
            position: "fixed",
            zIndex: 100,
            top: 10,
            right: 10,
            color: "#4bbfae",
            marginRight: "5vw"
          }}
          size="2x"
          icon={faBars}
        />
        <MobileMenu
          open={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}
        >
          <Link
            to="about"
            spy={true}
            offset={-200}
            smooth={"easeInOutQuint"}
            duration={500}
            onClick={() => setMobileMenuOpen(false)}
            className="mobile-version-link"
          >
            <h2>ABOUT</h2>
          </Link>
          <Link
            to="projects"
            spy={true}
            smooth={"easeInOutQuint"}
            offset={-200}
            duration={500}
            onClick={() => setMobileMenuOpen(false)}
            className="mobile-version-link"
          >
            <h2>CUSTOMERS</h2>
          </Link>
          <Link
            to="contact"
            spy={true}
            smooth={"easeInOutQuint"}
            offset={-200}
            duration={500}
            onClick={() => setMobileMenuOpen(false)}
            className="mobile-version-link"
          >
            <h2>CONTACT</h2>
          </Link>
        </MobileMenu>
      </div>
    )
  }

  return (
    <div
      className={`header-container ${sticky ? " sticky" : ""}`}
      ref={headerRef}
    >
      <motion.h1
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.9 }}
        className="header-container-title"
      >
        {sticky ? null : <img src={ImageLogo} alt="placeholder" />}
      </motion.h1>
      <div className={`header-navigation-container ${sticky ? " dark" : ""}`}>
        <Link
          activeClass="active"
          to="about"
          spy={true}
          offset={-200}
          smooth={"easeInOutQuint"}
          duration={500}
        >
          <h2>ABOUT</h2>
        </Link>
        <Link
          activeClass="active"
          to="projects"
          spy={true}
          smooth={"easeInOutQuint"}
          offset={-200}
          duration={500}
        >
          <h2 style={{ marginLeft: "1.5rem" }}>PROJECTS</h2>
        </Link>
        <Link
          activeClass="active"
          to="contact"
          spy={true}
          smooth={"easeInOutQuint"}
          offset={-200}
          duration={500}
        >
          <h2 style={{ marginLeft: "1.5rem" }}>CONTACT</h2>
        </Link>
      </div>
    </div>
  )
}

export default Header
