import React, { Component } from "react"
import { Switch, Route, Redirect } from "react-router-dom"

import Main from "./pages/Main"

class Router extends Component {
  constructor(props) {
    super(props)
    this.state = {
      width: 0,
      height: 0
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener("resize", this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  render() {
    return (
      <Switch>
        <Route
          exact
          path={"/"}
          render={(props) => (
            <Main
              {...props}
              width={this.state.width}
              height={this.state.height}
            />
          )}
        />
        <Redirect
          to={{
            pathname: "/"
          }}
        />
      </Switch>
    )
  }
}

export default Router
