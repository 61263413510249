import React from "react"

import "./style.css"
import Hydroware from "assets/images/logga_hydroware.png"
import Homepal from "assets/images/logga_homepal.svg"
import Kai from "assets/images/NeonAnimationTransparent.json"

import { Player } from "@lottiefiles/react-lottie-player"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay } from "swiper"

import * as Functions from "helpers/Functions"

// Import Swiper styles
import "swiper/swiper.scss"
import "swiper/components/scrollbar/scrollbar.scss"

SwiperCore.use([Autoplay])

const Projects = () => {
  const { width } = Functions.useWindowDimensions()
  const mobile = width < 640 ? "mobile-" : ""

  return (
    <Swiper
      centeredSlides={true}
      autoplay={{ delay: 2000 }}
      slidesPerView={1}
      effect="fade"
      className={`${mobile}projects-container`}
    >
      <SwiperSlide className="projects-container-slide">
        <img src={Hydroware} alt="hydroware" />
      </SwiperSlide>
      <SwiperSlide className="projects-container-slide">
        <img src={Homepal} alt="homepal" />
      </SwiperSlide>
      <SwiperSlide className="projects-container-slide">
        <Player
          autoplay
          loop
          rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
          src={Kai}
          style={{
            height: !!mobile ? undefined : "10vh",
            width: !!mobile ? "100%" : undefined
          }}
        ></Player>
      </SwiperSlide>
    </Swiper>
  )
}

export default Projects
