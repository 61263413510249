import React, { useState } from "react"
import TextareaAutosize from "react-textarea-autosize"

import * as Functions from "helpers/Functions"

import "./style.css"

const ContactForm = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const { width } = Functions.useWindowDimensions()
  const fontSize = width < 640 ? "14pt" : "18pt"

  return (
    <div style={{ width: width < 640 ? "100vw" : "50vw" }}>
      <h1 className="contact-form-header">Want to come in contact with us?</h1>
      <h1 className="contact-form-header" style={{ marginBottom: "3rem" }}>
        Send us an email!
      </h1>
      <div className="contact-form">
        <div className="contact-form-row">
          <h2 style={{ fontSize }}>MY NAME: </h2>
          <input value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className="contact-form-row">
          <h2 style={{ fontSize }}>EMAIL: </h2>
          <input value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className="contact-form-row">
          <h2 style={{ fontSize }}>MESSAGE: </h2>
          <TextareaAutosize
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <div
          className="contact-form-row"
          style={{ justifyContent: "flex-end" }}
        >
          <h3 onClick={() => onSubmit({ name, email, message })}>SEND</h3>
        </div>
      </div>
    </div>
  )
}

const onSubmit = ({ name, email, message }) =>
  console.log({ name, email, message })

export default ContactForm
