import React from "react"

import Header from "../../containers/Header"
import Intro from "../../containers/Intro"
import Form from "../../containers/Form"
import Projects from "../../containers/Projects"

import "./style.css"

const Mainpage = () => {
  return (
    <div className="container">
      <Header />
      <div className="container-intro-section" id="about">
        <Intro />
      </div>
      <div className="container-projects-section" id="projects">
        <Projects />
      </div>
      <div className="container-form-section" id="contact">
        <Form />
      </div>
    </div>
  )
}

export default Mainpage
