import React, { useState } from "react"

import Image from "../../../assets/images/chad.png"
import ReactCardFlip from "react-card-flip"

import "./style.css"

const PersonCard = ({ style, name, title, skills }) => {
  const [flipped, doFlip] = useState(false)

  return (
    <ReactCardFlip isFlipped={flipped} flipDirection="horizontal">
      <div className="person-card" style={style} onClick={() => doFlip(true)}>
        <img src={Image} alt="placeholder" />
        <h5>{name}</h5>
        <h6>{title}</h6>
      </div>

      <div className="person-card" style={style} onClick={() => doFlip(false)}>
        <div className="skills">
          <p>
            {" "}
            Löksås ipsum flera färdväg träutensilierna mjuka denna helt, genom
            redan annan dock färdväg är.
            <br />
            <br />
            Annat sista stora sorgliga hans på som oss erfarenheter, oss bland
            blivit dock när för vid blev, söka precis söka träutensilierna är
            annan sax.{" "}
          </p>
        </div>
        <h5>{name}</h5>
        <h6>{title}</h6>
      </div>
    </ReactCardFlip>
  )
}

export default PersonCard
